import { useDispatch } from "react-redux";

import cookiesHelper from "../../helpers/cookies";

import { setSessionData, setSubscription, setSubscribedUser } from "../../store/slices/global";

const useSetSignInData = () => {

    const dispatch = useDispatch();

    const setSignInData = (data: any) => {
        const { auth_token, display_tour, two_factor_enabled, user_id, email, main, spouse, subscription, subscribedUser } = data;
        cookiesHelper.setCookie(process.env.REACT_APP_AUTH_TOKEN_NAME as string, auth_token, 30 * 60 * 1000);
        cookiesHelper.setCookie(process.env.REACT_APP_INSTANCE_TOKEN_NAME__IMMI_QUEST__MAIN as string, main?.primary_questionnaire?.instance_token as string, 24 * 60 * 60 * 1000);
        cookiesHelper.setCookie(process.env.REACT_APP_INSTANCE_TOKEN_NAME__CAREER_MAIN as string, main?.career?.token as string, 24 * 60 * 60 * 1000);
        cookiesHelper.setCookie(process.env.REACT_APP_INSTANCE_TOKEN_NAME__CAREER_SPOUSE as string, spouse?.career?.token as string, 24 * 60 * 60 * 1000);

        if (spouse) {
            cookiesHelper.setCookie(process.env.REACT_APP_INSTANCE_TOKEN_NAME__IMMI_QUEST__SPOUSE as string, spouse?.primary_questionnaire?.instance_token as string, 24 * 60 * 60 * 1000);
        };
        cookiesHelper.setCookie(process.env.REACT_APP_INSTANCE_TOKEN_NAME__VC_QUEST__MAIN as string, main?.visa_chances_questionnaire?.instance_token as string, 24 * 60 * 60 * 1000);

        if (main?.primary_questionnaire?.instance_token) delete main.primary_questionnaire.instance_token;
        if (spouse?.primary_questionnaire?.instance_token) delete spouse.primary_questionnaire.instance_token;
        if (main?.visa_chances_questionnaire?.instance_token) delete main.visa_chances_questionnaire.instance_token;

        dispatch(setSessionData({
            user_id,
            display_tour,
            two_factor_enabled,
            email,
            main,
            spouse,
            currentApplicant: main?.id
        }));
        dispatch(setSubscription(subscription));
        dispatch(setSubscribedUser(subscribedUser));
    };

    return setSignInData;
};

export default useSetSignInData;