import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';

import reportWebVitals from './reportWebVitals';

import App from './components/App';

import NavigationRoutes from './routes';

import { store, persistor } from './store';

import { initGA4, initGTM, initHotjar, MetaPixel } from './helpers/tracking';

export const Root = () => {

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  // deployment temporary update - 4

  useEffect(() => {
    initGA4();
    initGTM();
    initHotjar();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter basename='/'>
            <MetaPixel />
            <App>
              <NavigationRoutes />
            </App>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
};

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(<Root />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
