import { getClient } from "../helpers/utility";

const BUCKET_BASE_URL = `https://${process.env.REACT_APP_S3_BUCKET__ASSETS}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com`;

const BUCKET_IMAGES_URL = `${BUCKET_BASE_URL}/images`;
const BUCKET_ICONS_OLD_URL = `${BUCKET_BASE_URL}/icons`;
const BUCKET_MEDIA_URL = `${BUCKET_BASE_URL}/media`;
const BUCKET_LOGO_URL = `${BUCKET_BASE_URL}/logos`;

export const iconAssets = {
  DownArrowIcon: `${BUCKET_ICONS_OLD_URL}/icon-arrow-down-dark.svg`,
  LeftArrowIcon: `${BUCKET_ICONS_OLD_URL}/icon-arrow-left-white.svg`,
  RightArrowIcon: `${BUCKET_ICONS_OLD_URL}/icon-arrow-right-white.svg`,
  SuccessCheckCircle: `${BUCKET_ICONS_OLD_URL}/checkmark-success.svg`,
  DisabledCheckCircle: `${BUCKET_ICONS_OLD_URL}/checkmark-disabled.svg`,
  PrimaryRightArrowIcon: `${BUCKET_ICONS_OLD_URL}/icon-arrow-right-primary.svg`,
  UpArrowIcon: `${BUCKET_ICONS_OLD_URL}/icon-arrow-up-dark.svg`,
  ClapIcon: `${BUCKET_ICONS_OLD_URL}/icon-clap.png`,
  CrossIcon: `${BUCKET_ICONS_OLD_URL}/icon-cross.png`,
  FilterIcon: `${BUCKET_ICONS_OLD_URL}/icon-filter.png`,
  HeartIcon: `${BUCKET_ICONS_OLD_URL}/icon-heart.png`,
  LinkIcon: `${BUCKET_ICONS_OLD_URL}/icon-link.png`,
  NewsIcon: `${BUCKET_ICONS_OLD_URL}/icon-news.png`,
  NotificationsIcon: `${BUCKET_ICONS_OLD_URL}/icon-notifications.png`,
  PlusIcon: `${BUCKET_ICONS_OLD_URL}/icon-plus.png`,
  QuestionnaireIcon: `${BUCKET_ICONS_OLD_URL}/icon-questionnaire.png`,
  SearchIcon: `${BUCKET_ICONS_OLD_URL}/icon-search.png`,
  ResultsIcon: `${BUCKET_ICONS_OLD_URL}/icon-results.png`,
  UsersIcon: `${BUCKET_ICONS_OLD_URL}/icon-users.png`,
  LeafIcon: `${BUCKET_ICONS_OLD_URL}/leaf.svg`,
  FiftyYrsIcon: `${BUCKET_ICONS_OLD_URL}/50yrs.svg`,
  RatingIcon: `${BUCKET_ICONS_OLD_URL}/rating.svg`,
  PlaneIcon: `${BUCKET_ICONS_OLD_URL}/plane.svg`,
  QualifyIcon: `${BUCKET_ICONS_OLD_URL}/qualify.png`,
  StarIcon: `${BUCKET_ICONS_OLD_URL}/star.svg`,
  GoogleIcon: `${BUCKET_ICONS_OLD_URL}/google.svg`,
  FacebookIcon: `${BUCKET_ICONS_OLD_URL}/Facebook.svg`,
  VideoCallIcon: `${BUCKET_ICONS_OLD_URL}/video_call.svg`,
  StarReviewIcon: `${BUCKET_ICONS_OLD_URL}/star_review.svg`,
  TrustShieldIcon: `${BUCKET_ICONS_OLD_URL}/trust_shield.svg`,
  SubscribeDiamondIcon: `${BUCKET_ICONS_OLD_URL}/subscribe_diamond.svg`,
  GoogleAuthenticatorIcon: `${BUCKET_ICONS_OLD_URL}/google_authenticator.png`,
  MicrosoftAuthenticatorIcon: `${BUCKET_ICONS_OLD_URL}/microsoft_authenticator.png`,
  EmailAuthenticatorIcon: `${BUCKET_ICONS_OLD_URL}/email_authenticator.png`,
  PhoneAuthenticatorIcon: `${BUCKET_ICONS_OLD_URL}/phone_authenticator.png`,
  ChatbotIcon: `${BUCKET_ICONS_OLD_URL}/chatbot.png`,
  SuccessCheckCircleIcon: `${BUCKET_ICONS_OLD_URL}/checkmark-success.svg`,
  PrimaryDropDownIcon: `${BUCKET_ICONS_OLD_URL}/icon-arrow-down-primary.svg`,
  ChatIcon: `${BUCKET_ICONS_OLD_URL}/chat.png`,
  EmailIcon: `${BUCKET_ICONS_OLD_URL}/email.png`,
  ChatAIBlackIcon: `${BUCKET_ICONS_OLD_URL}/chat_ai_black.png`,
  ChatAIWhiteIcon: `${BUCKET_ICONS_OLD_URL}/chat_ai_white.png`,
  ChatLiveExpertBlackIcon: `${BUCKET_ICONS_OLD_URL}/chat_live_expert_black.png`,
  ChatLiveExpertWhiteIcon: `${BUCKET_ICONS_OLD_URL}/chat_live_expert_white.png`,
};

export const imageAssets = {
  FlyingPaperPlane: `${BUCKET_IMAGES_URL}/flying_paper_plane.png`,
  MobileSignupBanner: `${BUCKET_IMAGES_URL}/mobile_signup_banner.png`,
  PaymentBanner1: `${BUCKET_IMAGES_URL}/payment_bg1.png`,
  PaymentBanner2: `${BUCKET_IMAGES_URL}/payment_bg2.png`,
  PaymentBanner3: `${BUCKET_IMAGES_URL}/payment_bg3.png`,
  ResetPassword: `${BUCKET_IMAGES_URL}/reset_password.png`,
  SuccessCelebrate: `${BUCKET_IMAGES_URL}/success_celebrate.png`,
  WebLandingBanner: `${BUCKET_IMAGES_URL}/web_landing_world.png`,
  BookConsultBanner: `${BUCKET_IMAGES_URL}/book_consult.png`,
  BookConsult2Banner: `${BUCKET_IMAGES_URL}/book_consult_v2.png`,
  MeetConsultBanner: `${BUCKET_IMAGES_URL}/meet_consult.png`,
  WantMoreOptions: `${BUCKET_IMAGES_URL}/want_more_options.png`,
  highestScore: `${BUCKET_IMAGES_URL}/highest_score.png`,
  yourScore: `${BUCKET_IMAGES_URL}/your_score.png`,
  ScoreMatrix: `${BUCKET_IMAGES_URL}/score_matrix.png`,
  NoBs: `${BUCKET_IMAGES_URL}/no_bs.png`,
  UpArrow: `${BUCKET_IMAGES_URL}/up-arrow.svg`,
  VisaChancesHigh: `${BUCKET_IMAGES_URL}/visa-chances-high.png`,
  VisaChancesMedium: `${BUCKET_IMAGES_URL}/visa-chances-medium.png`,
  VisaChancesLow: `${BUCKET_IMAGES_URL}/visa-chances-low.png`,
  VisaChancesPromptHigh: `${BUCKET_IMAGES_URL}/visa-chances-prompt-high.png`,
  ImmprovedAI: `${BUCKET_IMAGES_URL}/immproved_ai.png`,
};

export const mediaAssets = {
  Rocket: `${BUCKET_MEDIA_URL}/rocket.mp4`,
};

interface LogoSet {
  LogoWhite: string;
  Logo: string;
  Monogram: string;
};
export const getLogoSet = (): LogoSet => {
  const client = getClient();

  return {
    LogoWhite: `${BUCKET_LOGO_URL}/${client}_white.svg`,
    Logo: `${BUCKET_LOGO_URL}/${client}_color.svg`,
    Monogram: `${BUCKET_LOGO_URL}/${client}_monogram_color.svg`,
  };
};

